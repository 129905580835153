"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteUserAPI = exports.UpdateUserAPI = exports.GetUserAPI = exports.GetUsersAPI = void 0;
var apiGenerator_1 = require("./apiGenerator");
var GetUsersAPI = function (_a) {
    var auth_token = _a.auth_token;
    return (0, apiGenerator_1.getAuthenticatedApi)({
        url: "/admin/users",
        auth_token: auth_token,
    });
};
exports.GetUsersAPI = GetUsersAPI;
var GetUserAPI = function (_a) {
    var auth_token = _a.auth_token, userId = _a.userId;
    return (0, apiGenerator_1.getAuthenticatedApi)({
        url: "/admin/users/".concat(userId),
        auth_token: auth_token,
    });
};
exports.GetUserAPI = GetUserAPI;
var UpdateUserAPI = function (_a) {
    var auth_token = _a.auth_token, updatedUser = __rest(_a, ["auth_token"]);
    return (0, apiGenerator_1.putAuthenticatedApi)({
        url: "/admin/users/".concat(updatedUser.id),
        auth_token: auth_token,
        body: updatedUser,
    });
};
exports.UpdateUserAPI = UpdateUserAPI;
var DeleteUserAPI = function (_a) {
    var auth_token = _a.auth_token, userId = _a.userId;
    return (0, apiGenerator_1.deleteAuthenticatedApi)({
        url: "/admin/users/".concat(userId),
        auth_token: auth_token,
    });
};
exports.DeleteUserAPI = DeleteUserAPI;
