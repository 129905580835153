// @ts-nocheck
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";

import "./scss/style.scss";
import { CurrentAdminAPI } from "./api";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
// const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  const { auth_token, isLoggedIn, admin } = useSelector(
    (state) => state.adminState
  );
  const [fetchUser, setFetchUser] = useState(false);

  const dispatch = useDispatch();

  const getCurrentAdmin = async () => {
    if (!isLoggedIn) {
      return;
    }

    const { response, errors } = await CurrentAdminAPI({ auth_token });

    if (!response || errors) {
      dispatch({
        type: "set",
        adminState: {
          auth_token: "",
          isLoggedIn: false,
          admin: {},
        },
      });
      return;
    }
    dispatch({
      type: "set",
      adminState: {
        auth_token,
        isLoggedIn: true,
        admin: response.admin,
      },
    });
  };

  useEffect(() => {
    getCurrentAdmin();
    // const timer = setTimeout(() => setFetchUser((prev) => !prev), 2 * 1000);
    const clearTimer = setInterval(() => getCurrentAdmin(), 20 * 1000);

    return () => {
      // clearTimeout(timer);
      clearInterval(clearTimer);
    };
  }, [isLoggedIn]);

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          {isLoggedIn && auth_token && admin?.id ? (
            <>
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </>
          ) : (
            <>
              {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
              <Route
                exact
                path="/login"
                name="Login Page"
                element={<Login />}
              />
              <Route
                path="*"
                element={<Navigate to={{ pathname: "/login" }} />}
              />
            </>
          )}
        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default App;
