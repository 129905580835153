"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateAdminPasswordAPI = exports.UpdateAdminAPI = exports.placeholder4 = void 0;
var apiGenerator_1 = require("./apiGenerator");
exports.placeholder4 = "";
var UpdateAdminAPI = function (_a) {
    var auth_token = _a.auth_token, updatedAdmin = __rest(_a, ["auth_token"]);
    return (0, apiGenerator_1.putAuthenticatedApi)({
        url: "/admin/account/update",
        auth_token: auth_token,
        body: updatedAdmin,
    });
};
exports.UpdateAdminAPI = UpdateAdminAPI;
var UpdateAdminPasswordAPI = function (_a) {
    var auth_token = _a.auth_token, oldPassword = _a.oldPassword, newPassword = _a.newPassword;
    return (0, apiGenerator_1.putAuthenticatedApi)({
        url: "/admin/account/update-password",
        auth_token: auth_token,
        body: {
            oldPassword: oldPassword,
            newPassword: newPassword,
        },
    });
};
exports.UpdateAdminPasswordAPI = UpdateAdminPasswordAPI;
