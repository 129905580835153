"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateReferralSettingsAPI = exports.GetReferralSettingsAPI = void 0;
var apiGenerator_1 = require("./apiGenerator");
var GetReferralSettingsAPI = function (_a) {
    var auth_token = _a.auth_token;
    return (0, apiGenerator_1.getAuthenticatedApi)({
        url: "/admin/referrals",
        auth_token: auth_token,
    });
};
exports.GetReferralSettingsAPI = GetReferralSettingsAPI;
var UpdateReferralSettingsAPI = function (_a) {
    var auth_token = _a.auth_token, settings = __rest(_a, ["auth_token"]);
    return (0, apiGenerator_1.postAuthenticatedApi)({
        url: "/admin/referrals/set",
        auth_token: auth_token,
        body: settings,
    });
};
exports.UpdateReferralSettingsAPI = UpdateReferralSettingsAPI;
