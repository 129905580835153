"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BetTypes = exports.BetResults = exports.WithdrawalRequestStatuses = void 0;
var WithdrawalRequestStatuses;
(function (WithdrawalRequestStatuses) {
    WithdrawalRequestStatuses[WithdrawalRequestStatuses["notProcessed"] = 0] = "notProcessed";
    WithdrawalRequestStatuses[WithdrawalRequestStatuses["rejected"] = 1] = "rejected";
    WithdrawalRequestStatuses[WithdrawalRequestStatuses["accepted"] = 2] = "accepted";
})(WithdrawalRequestStatuses || (exports.WithdrawalRequestStatuses = WithdrawalRequestStatuses = {}));
var BetResults;
(function (BetResults) {
    BetResults["win"] = "win";
    BetResults["loss"] = "loss";
    BetResults["notProcessed"] = "not processed";
})(BetResults || (exports.BetResults = BetResults = {}));
var BetTypes;
(function (BetTypes) {
    BetTypes["high"] = "high";
    BetTypes["low"] = "low";
})(BetTypes || (exports.BetTypes = BetTypes = {}));
