"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrentAdminAPI = exports.LoginAPI = void 0;
var apiGenerator_1 = require("./apiGenerator");
var LoginAPI = function (loginRequestObj) {
    return (0, apiGenerator_1.postUnauthenticatedApi)({
        url: "/admin/auth/login",
        body: loginRequestObj,
    });
};
exports.LoginAPI = LoginAPI;
var CurrentAdminAPI = function (_a) {
    var auth_token = _a.auth_token;
    return (0, apiGenerator_1.getAuthenticatedApi)({
        url: "/admin/auth/current-admin",
        auth_token: auth_token,
    });
};
exports.CurrentAdminAPI = CurrentAdminAPI;
