"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.errorParser = void 0;
// eslint-disable-next-line
var errorParser = function (error) {
    var _a;
    if (!error || !((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || !error.response.data.errors) {
        return [
            {
                message: "Error connecting to server",
            },
        ];
    }
    return error.response.data.errors;
};
exports.errorParser = errorParser;
